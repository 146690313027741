<script setup lang="ts">
const { config } = useAppState()
const isCN = computed(()=> !!config?.value?.location?.country_code && config.value.location.country_code === 'CN')
</script>

<template>
  <div v-if="isCN" class="tips w-full bg-#256DF1 text-white p-3.5 text-center text-4 leading-5">
    {{ $t('PVQhIP3hYKP3sWtrLrr6') }}
    <a rel="nofollow noopener noreferrer " target="_blank" class="text-#E4C11D underline !underline-offset-4" href="/blog/announcement/to-chinese-users-feeeio-product-policy-adjustment-notice-22"> {{ $t('02Wh90Uqe71iQokAi6gU') }}</a>
  </div>
</template>
